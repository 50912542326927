import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"

// Project components
import { NextPage, SEO } from "components"

// Page sections
import IntroSection from "./_introSection"
import StatSection from "./_statSection"
import CharitySection from "./_charitySection"
import HighlightsSection from "./_highlightsSection"
import FullImageSection from "./_fullImageSection"

function SustainabilityPage() {
  const staticData = useStaticQuery(graphql`
    {
      prismicSustainability {
        data {
          seo_title {
            text
          }
          seo_description {
            text
          }
          seo_image {
            url
            alt
          }
          hero_image {
            fluid(maxWidth: 1200) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
          hero_title {
            text
          }
          hero_description {
            html
          }
          featured_stat
          featured_stat_label
          charity_description {
            html
          }
          charity_image {
            fluid(maxWidth: 600) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
          charity_button_text
          charity_button_link {
            url
          }
          highlights_title {
            text
          }
          highlights_description {
            html
          }
          featured_highlights {
            logo {
              fluid(maxWidth: 256) {
                ...GatsbyPrismicImageFluid
              }
              alt
            }
            title
            highlight
            link {
              url
            }
          }
          highlights_image {
            fluid(maxWidth: 1900) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
          next_page_image {
            fluid(maxWidth: 600) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
          next_page_title {
            text
          }
          next_page_button_text
          next_page_button_link {
            slug
          }
        }
      }
    }
  `)
  const previewData =
    typeof window !== "undefined" && window.__PRISMIC_PREVIEW_DATA__
  const finalData = mergePrismicPreviewData({ staticData, previewData })
  return (
    <>
      <SEO
        title={
          finalData.prismicSustainability.data.seo_title &&
          finalData.prismicSustainability.data.seo_title.text
        }
        description={
          finalData.prismicSustainability.data.seo_description &&
          finalData.prismicSustainability.data.seo_description.text
        }
        image={
          finalData.prismicSustainability.data.seo_image &&
          finalData.prismicSustainability.data.seo_image.url
        }
        imageAlt={
          finalData.prismicSustainability.data.seo_image &&
          finalData.prismicSustainability.data.seo_image.alt
        }
      />
      {/* ============ INTRO ============ */}
      <IntroSection
        id="sustainability-intro"
        data={{
          image:
            finalData.prismicSustainability.data.hero_image &&
            finalData.prismicSustainability.data.hero_image.fluid &&
            finalData.prismicSustainability.data.hero_image,
          title:
            finalData.prismicSustainability.data.hero_title &&
            finalData.prismicSustainability.data.hero_title.text,
          bodyContent:
            finalData.prismicSustainability.data.hero_description &&
            finalData.prismicSustainability.data.hero_description.html,
        }}
      />
      {/* ============ STAT ============ */}
      <StatSection
        id="sustainability-stat"
        data={{
          statValue: finalData.prismicSustainability.data.featured_stat,
          statDescription:
            finalData.prismicSustainability.data.featured_stat_label,
        }}
      />
      {/* ============ CHARITY ============ */}
      <CharitySection
        id="sustainability-charity"
        data={{
          image:
            finalData.prismicSustainability.data.charity_image &&
            finalData.prismicSustainability.data.charity_image.fluid &&
            finalData.prismicSustainability.data.charity_image,
          bodyContent:
            finalData.prismicSustainability.data.charity_description &&
            finalData.prismicSustainability.data.charity_description.html,
          buttonLabel: finalData.prismicSustainability.data.charity_button_text,
          buttonTo: finalData.prismicSustainability.data.charity_button_link,
        }}
      />
      {/* ============ HIGHLIGHTS ============ */}
      <HighlightsSection
        id="sustainability-highlights"
        data={{
          title:
            finalData.prismicSustainability.data.highlights_title &&
            finalData.prismicSustainability.data.highlights_title.text,
          bodyContent:
            finalData.prismicSustainability.data.highlights_description &&
            finalData.prismicSustainability.data.highlights_description.html,
          cards:
            finalData.prismicSustainability.data.featured_highlights &&
            finalData.prismicSustainability.data.featured_highlights.map(
              ({ logo, title, highlight, link }) => ({
                title: title,
                image: logo,
                description: highlight,
                link: link,
              })
            ),
        }}
      />
      {/* ============ FULL IMAGE ============ */}
      <FullImageSection
        id="sustainability-fullImage"
        data={{ image: finalData.prismicSustainability.data.highlights_image }}
      />
      {/* ============ NEXT PAGE ============ */}
      <NextPage
        id="sustainability-nextPage"
        image={finalData.prismicSustainability.data.next_page_image}
        title={
          finalData.prismicSustainability.data.next_page_title &&
          finalData.prismicSustainability.data.next_page_title.text
        }
        buttonLabel={finalData.prismicSustainability.data.next_page_button_text}
        buttonTo={
          "/" +
          finalData.prismicSustainability.data.next_page_button_link.slug +
          "/"
        }
      />
    </>
  )
}

export default SustainabilityPage
