import React from "react"
import Image from "gatsby-image/withIEPolyfill"
import { mdiArrowRight } from "@mdi/js"

// Project components
import {
  AspectRatio,
  Box,
  Button,
  FlexGrid,
  Link,
  RichText,
  Wrapper,
} from "components"

const CharitySection = ({ data, id }) => {
  return (
    <>
      {/* We only render the section if it has props set */}
      {(data.bodyContent || data.buttonLabel || data.buttonTo) && (
        <Wrapper id={id}>
          <FlexGrid
            content={[
              {
                width: ["100%", "50%"],
                children: (
                  <Box>
                    {/* Content */}
                    {data.bodyContent && (
                      <RichText
                        content={{
                          html: data.bodyContent,
                        }}
                        size={600}
                        css={{
                          strong: {
                            fontSize: 32,
                            lineHeight: 1.1,
                          },
                          p: {
                            fontSize: 18,
                            lineHeight: 1.2,
                          },
                        }}
                      />
                    )}

                    {/* Button Link */}
                    {(data.buttonLabel || data.buttonTo) && (
                      <Button
                        as={Link}
                        to={data.buttonTo}
                        iconAfter={mdiArrowRight}
                        intent="primary"
                        appearance="primary"
                        mt={["layout.4", "layout.6"]}
                      >
                        {data.buttonLabel}
                      </Button>
                    )}
                  </Box>
                ),
              },
              {
                width: ["100%", "50%"],
                children: (
                  <AspectRatio ratio="4:3" borderRadius={16} bg="wash">
                    <Box p={"layout.4"} height="100%">
                      <Box position="relative" height="100%" width="100%">
                        {data.image && data.image.fluid && (
                          <Image
                            fluid={data.image.fluid}
                            alt={data.image.alt}
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                            }}
                            objectFit="contain"
                            objectPosition="center"
                          />
                        )}
                      </Box>
                    </Box>
                  </AspectRatio>
                ),
              },
            ]}
            alignItems="center"
          />
        </Wrapper>
      )}
    </>
  )
}

export default CharitySection
