import React from "react"

import { FluidText, Stat, Wrapper } from "components"

const StatSection = ({ data, id }) => {
  return (
    <>
      {/* We only render the section if it has props set */}
      {(data.statValue || data.statDescription) && (
        <Wrapper id={id}>
          {data.statValue && data.statDescription && (
            <FluidText
              mt={["layout.4", null, "layout.6"]}
              color="brand.secondary"
            >
              <Stat value={data.statValue} />
              {" " + data.statDescription}
            </FluidText>
          )}
        </Wrapper>
      )}
    </>
  )
}

export default StatSection
