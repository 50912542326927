import React from "react"
import css from "@styled-system/css"

import {
  Box,
  CommunityCard,
  Flex,
  Heading,
  RichText,
  Wrapper,
} from "components"

const HighlightsSection = ({ data, id }) => {
  return (
    <>
      {/* We only render the section if it has props set */}
      {(data.title || data.cards) && (
        <Wrapper id={id}>
          {/* Title */}
          {data.title && (
            <Heading size={800} mb={["layout.2", null, "layout.3"]}>
              {data.title}
            </Heading>
          )}

          {/* Content */}
          {data.bodyContent && (
            <RichText
              content={{
                html: data.bodyContent,
              }}
              size={500}
              mb={["layout.4", null, "layout.6"]}
            />
          )}

          {/* Cards */}
          {data.cards && (
            <Box mx={["-16px", "-32px"]}>
              <Flex
                flexWrap="wrap"
                m={[-2, -16]}
                css={css({
                  "> *": {
                    width: ["100%", "50%", "33.333%"],
                    p: [2, 16],
                  },
                })}
              >
                {data.cards.map((card, index) => (
                  <Box>
                    <CommunityCard
                      image={card.image}
                      link={card.link}
                      title={card.title}
                      description={card.description}
                      key={"highlight" + index}
                    />
                  </Box>
                ))}
              </Flex>
            </Box>
          )}
        </Wrapper>
      )}
    </>
  )
}

export default HighlightsSection
