import React from "react"
import Image from "gatsby-image/withIEPolyfill"

import { AspectRatio, Wrapper } from "components"

const FullImageSection = ({ data, id }) => {
  return (
    <Wrapper id={id} maxWidth={null} px={null}>
      <AspectRatio ratio="16:9" bg="wash">
        {data.image && data.image.fluid && (
          <Image
            fluid={data.image.fluid}
            alt={data.image.alt}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            objectFit="cover"
            objectPosition="center"
          />
        )}
      </AspectRatio>
    </Wrapper>
  )
}

export default FullImageSection
